import { createError, defineNuxtRouteMiddleware } from '#imports'

import { getSourcesStore } from '@/stores/sources'

export default defineNuxtRouteMiddleware(async (to) => {
  const sources = await getSourcesStore()

  const source = sources.getById(String(to.params.id))

  if (source) {
    return
  }

  return createError({ statusCode: 404 })
})
